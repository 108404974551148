import React from 'react';

import get from 'lodash/get';

import Custom404 from 'pages/404';
import ErrorPage from 'pages/_error';
import { CODE_500 } from 'constants/errors';
import {
  Footer,
  FooterForm,
  Navigation,
  LowerNavigation,
} from 'components/organisms';

export default function PageDataLoader({
  WrappedComponent,
  showLowerNavHome = false,
  componentId,
  navigationData,
  navCollectionName,
  footerData,
  footerCollectionName,
  pageData,
  pageError,
  pageLoading,
}) {
  if (pageLoading) return <section>Loading... (Spinner component?)</section>;

  if (pageError) {
    // Handling nextjs pages/_error.js manually in case of Graphql errors
    const initialProps = ErrorPage.getInitialProps({
      err: { statusCode: CODE_500 },
    });

    return <ErrorPage {...initialProps} />;
  }

  if (!pageData) return <Custom404 />;

  const homepageData = get(
    navigationData,
    `${navCollectionName}.items[0].navigationItemsCollection.items`,
    null,
  )?.filter(item => item.type !== 'Dropdown');
  const hideNavigationLinks =
    get(pageData, 'hideNavigationLinks', false) || false;
  const getHero = get(pageData, 'hero', null) || null;
  const hasHero = !!getHero;
  const lowerNavigationData = get(
    pageData,
    'lowerNavigation.navigationItemsCollection.items',
    [] || '',
  );
  const lowerNavigationSlug =
    get(pageData, 'lowerNavigation.slug', null) || null;
  const showLocaleSelector =
    get(pageData, 'lowerNavigation.showLocaleSelector', false) || false;
  const isBackButton =
    get(pageData, 'lowerNavigation.isBackButton', false) || false;
  const isBreadcrumb =
    get(pageData, 'lowerNavigation.isBreadcrumb', false) || false;
  const hideFooterLinks = get(pageData, 'hideFooterLinks', false) || false;
  const hideContactForm = get(pageData, 'hideContactForm', false) || false;

  return (
    <>
      {navigationData && (
        <Navigation
          data={navigationData}
          collectionName={navCollectionName}
          hideNavigationLinks={hideNavigationLinks}
          backgroundColor={pageData?.hero?.backgroundColor}
          theme={pageData?.hero?.theme}
          hasHero={hasHero}
        />
      )}
      {((lowerNavigationData && lowerNavigationData.length > 0) ||
        showLocaleSelector) && (
        <LowerNavigation
          lowerNavigationData={lowerNavigationData}
          hideNavigationLinks={hideNavigationLinks}
          backgroundColor={pageData?.hero?.backgroundColor}
          theme={pageData?.hero?.theme}
          showLocaleSelector={showLocaleSelector}
          isBreadcrumb={isBreadcrumb}
          isBackButton={isBackButton}
          lowerNavigationSlug={lowerNavigationSlug}
        />
      )}
      {showLowerNavHome && homepageData && homepageData.length > 0 && (
        <LowerNavigation lowerNavigationData={homepageData} isHomepage />
      )}
      <main id="main-content" role="main">
        <WrappedComponent key={componentId} pageData={pageData} />
      </main>
      {!hideContactForm && <FooterForm />}
      {footerData && (
        <Footer
          hideFooterLinks={hideFooterLinks}
          data={footerData}
          collectionName={footerCollectionName}
          showFooterLocaleSelector={!showLocaleSelector}
        />
      )}
    </>
  );
}
