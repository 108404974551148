import logger from '@spotify-internal/isomorphic-logger';

import { supportedLocaleCodes } from 'i18n/config';
import * as cache from 'utils/cache-handler';
import { QUERIES, DYNAMIC_COLLECTIONS } from 'queries/page';
import { getCollectionName } from 'utils/get-collection-name';
import { moduleQuerySplitter } from 'utils/module-query-splitter';
import {
  FOOTER_QUERY,
  DYNAMIC_COLLECTION as FOOT_COLLECTION,
} from 'queries/footer';
import {
  NAVIGATION_QUERY,
  DYNAMIC_COLLECTION as NAV_COLLECTION,
} from 'queries/navigation';
import { createApolloClient } from 'apollo/client';
import { QUERY_TYPES } from 'constants/query';
import { get } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import startCase from 'lodash/startCase';

const [defaultLocale] = supportedLocaleCodes;

const HOME_SLUG = 'home';
const MAIN_NAVIGATION_SLUG = 'global-navigation';
const FOOTER_SLUG = 'global-footer';
const DEFAULT_ARGS = {
  pageSlug: true,
  queryType: QUERY_TYPES.PAGE_DETAIL_GLOBAL_QUERY,
  locale: defaultLocale,
  tag: null,
  slug: null,
};

async function DataQuery(data, fallback) {
  const { locale, variables, queryType, apolloClient, pathname } = data;
  // verifiedLocale is in the format "en-CA" while collectionLocale is in the format "EnCa"
  const verifiedLocale = supportedLocaleCodes.includes(locale)
    ? locale
    : defaultLocale;
  const collectionLocale = startCase(verifiedLocale.toLowerCase()).replace(
    ' ',
    '',
  );
  let query;
  if (fallback) {
    switch (queryType) {
      case QUERY_TYPES.PAGE_DETAIL_GLOBAL_QUERY:
        query = QUERY_TYPES.PAGE_DETAIL_QUERY;
        break;
      default:
        query = queryType;
        break;
    }
  } else if (
    queryType === QUERY_TYPES.PAGE_DETAIL_GLOBAL_QUERY &&
    (variables?.pageSlug === 'podcast-trends' ||
      variables?.pageSlug === 'gen-z-trends-report')
  ) {
    query = QUERY_TYPES.PAGE_DETAIL_GLOBAL_TRENDS_QUERY;
  } else {
    query = queryType;
  }

  const collectionName =
    query === QUERY_TYPES.PAGE_DETAIL_GLOBAL_QUERY
      ? 'pageDetailGlobalCollection'
      : getCollectionName(
          supportedLocaleCodes,
          verifiedLocale,
          DYNAMIC_COLLECTIONS[query],
        );

  const modulesQueries = moduleQuerySplitter(
    QUERIES[query].modules({
      collection: collectionName,
      collectionLocale,
    }),
  ).map(moduleQuery =>
    apolloClient.query({
      query: moduleQuery,
      variables: { locale: verifiedLocale, ...variables },
    }),
  );

  return Promise.all([
    apolloClient.query({
      query: QUERIES[query].body({
        collection: collectionName,
        collectionLocale,
      }),
      variables: { locale: verifiedLocale, ...variables },
    }),
    ...modulesQueries,
  ])
    .then(dataArray => {
      const bodyData = dataArray.shift();
      const [pageBody] = get(bodyData, `data.${collectionName}.items`, [null]);
      // get any modules returned from the first modules query
      const [pageModules] = get(
        dataArray.shift(),
        `data.${collectionName}.items`,
        [null],
      );
      // if there are additional modules queries that returned results, extract the data and merge them into the modules'
      // response object in the first pageModules response.
      // Clone data because the cache is read-only - https://github.com/apollographql/apollo-client/issues/5903#issuecomment-581466423
      const mergedPageModules = cloneDeep(pageModules);
      while (dataArray.length > 0) {
        const [pageAdditionalModules] = get(
          dataArray.shift(),
          `data.${collectionName}.items`,
          [null],
        );

        if (pageAdditionalModules) {
          pageAdditionalModules.modulesCollection.items.map((module, idx) => {
            if (Object.keys(module).length > 1) {
              mergedPageModules.modulesCollection.items[idx] = module;
            }
            return null;
          });
        }
      }

      // True for dynamic landing pages
      if (!pageBody && !mergedPageModules) {
        if (!fallback) {
          return DataQuery(data, true);
        }
        return { data: null };
      }

      const response = {
        data: {
          ...(pageBody || {}),
          ...(mergedPageModules || {}),
        },
      };

      if (pageBody && mergedPageModules) {
        cache.set(pathname, response);
      }

      return response;
    })
    .catch(err => {
      logger.error(`PageDataLoader error: ${err}`);
      return { error: err };
    });
}

export default async function fetchPageData(args) {
  const apolloClient = createApolloClient();
  const { pageSlug, queryType, locale, tag, slug } = {
    ...DEFAULT_ARGS,
    ...args,
  };
  // Navigation query
  const navCollection = getCollectionName(
    supportedLocaleCodes,
    locale,
    NAV_COLLECTION,
  );
  const cacheNavKey = `${MAIN_NAVIGATION_SLUG}/${locale || defaultLocale}`;
  const { data: navigationData } =
    cache.get(cacheNavKey) ||
    (await apolloClient
      .query({
        query: NAVIGATION_QUERY(navCollection),
        variables: {
          slug: MAIN_NAVIGATION_SLUG,
          locale,
        },
      })
      .then(response => {
        if (get(response, `data.${navCollection}.items`)) {
          cache.set(cacheNavKey, response);
        }

        return response;
      }));

  // Footer query
  const footCollection = getCollectionName(
    supportedLocaleCodes,
    locale,
    FOOT_COLLECTION,
  );
  const cacheFooterKey = `${FOOTER_SLUG}/${locale || defaultLocale}`;
  const { data: footerData } =
    cache.get(cacheFooterKey) ||
    (await apolloClient
      .query({
        query: FOOTER_QUERY(footCollection),
        variables: {
          slug: FOOTER_SLUG,
        },
      })
      .then(response => {
        if (get(response, `data.${footCollection}.items`)) {
          cache.set(cacheFooterKey, response);
        }

        return response;
      }));

  // Page Data Query
  const modifiedVariables = {
    ...(!pageSlug && { pageSlug: tag }), // A landing page has the same value for slug and navigation tag
  };
  const variables = {
    pageSlug: slug,
    tagSlug: tag,
    homeSlug: HOME_SLUG, // There can only be a single instance of the homepage
    ...modifiedVariables,
  };
  const pathname = [locale, tag, slug].filter(value => value).join('/');
  const { data: pageData, error, loading } =
    cache.get(pathname) ||
    (await DataQuery({
      variables,
      locale,
      queryType,
      apolloClient,
      pathname,
    }));

  return {
    navigationData,
    navCollectionName: navCollection,
    footerData,
    footerCollectionName: footCollection,
    pageData,
    pageError: error || false,
    pageLoading: loading || false,
  };
}
